import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, NavLink } from 'react-router-dom';

const LogoutButton = () => {
  const { user, logout, isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <NavLink to="profile">{user.picture ? <img className="profile-nav-img" src={user.picture} alt={user.name} /> : <div className="profile-nav-initials">{user.name[0]}</div>}</NavLink>
    )
  );
};

export default LogoutButton;

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PersonIcon from '@mui/icons-material/Person';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
      <div className="profile-nav-initials" onClick={() => loginWithRedirect()}><PersonIcon /></div>
    )
  );
};

export default LoginButton;

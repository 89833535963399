import React, { useContext, useEffect } from "react";
import { Video, Media } from '@vidstack/player-react';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PastEvents from './get-past-events';
import UserContext from '../components/user-data';


const Library = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const profile = useContext(UserContext);

  document.title = "ASEN - Library";
    
  window.location.href = 'https://www.youtube.com/channel/UC1w5OhnKag2-NgFY8S_gr4w';
    
//  window.open('https://www.youtube.com/channel/UC1w5OhnKag2-NgFY8S_gr4w', '_blank');

  if (isAuthenticated === false) {
    loginWithRedirect()
  }
  
//  if (isAuthenticated && profile) {
//      console.log(profile.fighter.firstName + " " + profile.fighter.lastName)
//  }
  
    
  return (
    isAuthenticated && (
      <div className="App-cover">
        <div className="App-container library">
          <PastEvents />
        </div>
      </div>
    )
  );
};

export default Library;

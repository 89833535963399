import React, { useEffect }  from "react";
import BuyCard from './buy-card';
import Moment from 'moment';
import { useAuth0 } from "@auth0/auth0-react";

const FutureEvents = () => {
  const { user, loginWithRedirect, isAuthenticated } = useAuth0();

  const [events, setEvents] = React.useState([]);
  const [buyCards] = React.useState([]);

  // Product Variables
  var key = '';
  var product = '';
  var livestream = '';
  var item_name = '';
  var item_url = '';
  var item_price = '';
  var item_video = '';
  var item_recording = '';
  var stream_provider = '';
  var stream_key = '';
  var ticketUrl = '';
  var isActive = '';

  var eventStartTime = '';
  var eventEndTime = '';
  var eventTimezone = '';

  var item_location = '';
  var item_address = '';

  var delay = false;
  var delayDate = '';

  var allowsFighters = false;

  var userEmail = '';
    
//  var server = '/api';    
  var server = 'https://ase-server.vercel.app/api/';

  if (isAuthenticated) {
    userEmail = user.email;
  }

  async function getEvents() {
    const fetchingEvent = await fetch (`${server}future-events`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userEmail,
      }),
    })
    .then((response) => response.json())
    .then((data) => {

      setEvents(data);
      // console.log(data)
    })
    .catch((error) => {
      console. error ('Error:', error);
    });
  }

  async function renderEvents(){
    //content
    const loadingEvents = await events.forEach((item, index)=>{
      product = item.id;
      livestream = item.event.stream;
      item_name = item.event.name;
      item_url = item.event.image;
      item_price = item.event.price;
      item_video = item.event.video;
      item_recording = item.event.recording;
      stream_provider = item.event.streamProvider;
      stream_key = item.event.stream;
      ticketUrl = item.event.tickets.buyLink;
      isActive = item.isActive;

      eventStartTime = item.event.start;
      eventEndTime = item.event.end;
      eventTimezone = item.event.timezone;

      item_location = item.event.venueName;
      item_address = item.event.venueAddress;

      delay = item.event.isDelayed;
      delayDate = item.event.delayedDate;

      allowsFighters = item.event.allowsFighters;

      // console.log(item_name);
        // const unique = [...new Set(buyCards.map((item) => item.key))];
      buyCards.push(<BuyCard product={product} livestream={livestream} item_name={item_name} item_url={item_url} item_price={item_price} item_video={item_video} item_recording={item_recording} stream_provider={stream_provider} stream_key={stream_key} ticketUrl={ticketUrl} eventStartTime={eventStartTime} eventEndTime={eventEndTime} eventTimezone={eventTimezone} item_location={item_location}  item_address={item_address} delay={delay} delayDate={delayDate} allowsFighters={allowsFighters} isActive={isActive} key={product} />);
    });
    // const unique = Array.from(new Set(buyCards));
  }


//  console.log(buyCards.length);

    if (events && events.length === 0) {
      getEvents();
//      console.log("Requested Future Events");
    }
    if (buyCards && buyCards.length === 0) {
      renderEvents();
    }

    let sortedBuyCards = buyCards.sort((b, a) => new Moment(b.props.eventStartTime).format('YYYYMMDD') < new Moment(a.props.eventStartTime).format('YYYYMMDD') ? -1 : 1);

    return sortedBuyCards;

};

export default FutureEvents;

export const eventCount = FutureEvents ; 

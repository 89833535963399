const prod = {
 url: {
  AUTH_URL: 'prod-asen.us.auth0.com',
  AUTH_KEY: 'dyhyXdYYpujNtb3skXM1Q1mSKVd2gQep'
 }
};
const dev = {
 url: {
  AUTH_URL: 'dev--wb7cokw.us.auth0.com',
  AUTH_KEY: 'iTlvJF6WHINwqrlqgQaxBHU4CwIbdqBl'
 }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;

//var domain = "";
//var clientId = "";

//Dev
//domain = "dev--wb7cokw.us.auth0.com";
//clientId = "iTlvJF6WHINwqrlqgQaxBHU4CwIbdqBl";

//prod
//domain = "prod-asen.us.auth0.com";
//clientId = "dyhyXdYYpujNtb3skXM1Q1mSKVd2gQep";
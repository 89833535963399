import React, { useEffect, useContext, useState, useRef }  from "react";
import { useLocation, useHistory, useNavigate } from 'react-router-dom';

import BuyCard from './buy-card';
import FutureEvents, {eventCount} from './get-future-events';
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';

import Alert from '@mui/material/Alert';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

import LocalActivityIcon from '@mui/icons-material/LocalActivity';

//import Autocomplete from "react-google-autocomplete";
//import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@mui/material/Tooltip';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

import { Link } from 'react-router-dom';

import Fade from '@mui/material/Fade';

import UserContext from '../components/user-data';

import imageExists from 'image-exists';

const short = require('short-uuid');

function Transition(props) {
  return <Fade {...props} direction="left" />;
}

const Home = (props) => {
  document.title = "Arte Suave Elite Network";
  const { user, loginWithRedirect, isAuthenticated } = useAuth0();
  
  const location = useLocation();
  const navigate = useNavigate();
    
  const [open, setOpen] = React.useState(true);
  const [addOpen, setAddOpen] = React.useState(false);
  const profile = useContext(UserContext);

  const vertical = 'top';
  const horizontal = 'right';
    
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const server = 'https://ase-server.vercel.app/api/';
  
  const [eventValues, setEventValues] = React.useState({
    isActive: true,
    isTest: false,
    isDelayed: false,
    delayedDate: null,
    eventTitle: null,
    eventSubtitle: null,
    streamProvider: 'Streaming Video Provider',
    streamKey: null,
    venueTickets: null,
    image: null,
    price: null,
    timezone: 'America/Los_Angeles',
    startTime: null,
    venueName: null,
    venueAddress: null,
    venueStreetAddress: null,
    venueSuite: "",
    venueCity: null,
    venueState: null,
    venueZip: null,
    video: "external",
    doorsOpen: 0,
    kidsAge: 8,
    ticketsGeneral: null,
    ticketsVIP: null,
    ticketsTable: null,
    ticketsKids: null,
  });
  
  var startTime = null;
  var formattedAddress = null;
  var validImage = null;
  var fullVenueAddress = null;
  var eventName = null;

  const [eventErrors, setEventErrors] = React.useState({});
  const [allowsFighters, setAllowsFighters] = React.useState(null);
  const [isActive, setIsActive] = React.useState(true);
  const [venueFullAddress, setVenueFullAddress] = React.useState();
  const [uuid, setUuid] = React.useState(null);
  const [recording, setRecording] = React.useState(null);
        
  const moment = require('moment-timezone');
  const today = Moment().format('ll');
  const now = Moment().format();
  const defaultDate = Moment(today + ' 16:00').add(1, 'd');
      
  const allTimeZones = moment.tz.zonesForCountry('US');
    
  if (location && location.state) {
    
    const { product, item_name, livestream, image, price, stream_provider, recording, ticketUrl, eventTimezone, eventStartTime, eventEndTime, venueName, venueAddress, allowsFighters, isActive } = location.state
//    console.log(product, item_name, livestream, image, price, stream_provider, recording, ticketUrl, eventTimezone, eventStartTime, eventEndTime, venueName, venueAddress, allowsFighters)
      
       
       if (item_name.includes("(")) {
           var splitName = item_name.split("(");
           var item_title = splitName[0].substring(0, splitName[0].length - 1);
           var item_subtitle = splitName[1].substring(0, splitName[1].length - 1);
       }
       else {
           item_title = item_name;
           item_subtitle = "";
       }
      
       var splitAddress = venueAddress.split(",");
       var eventStreetAddress = splitAddress[0];
       var eventCity = splitAddress[1];
       var eventState = splitAddress[2].substring(1, splitAddress[2].length - 6);
       var eventZip = splitAddress[2].substring(4);
      
       if (uuid !== product) {
            setUuid(product);
            setRecording(recording);
            setAllowsFighters(allowsFighters);
            setIsActive(isActive);
       }
      
      if (!eventValues.eventTitle) {
        setEventValues({
          isActive: isActive,
          isTest: false,
          isDelayed: false,
          delayedDate: null,
          eventTitle: item_title,
          eventSubtitle: item_subtitle,
          streamKey: livestream,
          venueTickets: ticketUrl,
          image: image,
          price: price.substring(1),
          streamProvider: stream_provider,
          recording: recording,
          timezone: eventTimezone,
          startTime: eventStartTime,
          venueName: venueName,
          venueStreetAddress: eventStreetAddress,
          venueCity: eventCity,
          venueState: eventState,
          venueZip: eventZip,
          allowsFighters: allowsFighters,
          video: "external",
          doorsOpen: 0,
          kidsAge: 8,
          ticketsGeneral: null,
          ticketsVIP: null,
          ticketsTable: null,
          ticketsKids: null,
        })
          
        setAddOpen(true);
      }
      
//      console.log(venueAddress)
  }
    
    
  else if (uuid === null || uuid === "") {
    setUuid(short.generate());
    setAllowsFighters(true);
    
    if (recording === null || recording === "") {
      setRecording(uuid)
    }
  }
    
//  console.log(uuid)
    
  function isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    }
    
    
  if (eventValues.image && eventValues.image.length !== 0) {
      validImage =
      
      imageExists(eventValues.image, image => {
         if (image) {
             return true;
         } 

         else {
             return false;
         }
      });
  }
    
    
  const eventValidate = () => {
    let temp= {}
    temp.eventTitle = eventValues.eventTitle &&  eventValues.eventTitle.length !== 0 && eventValues.eventTitle !== "" && eventValues.eventTitle !== null ? "" : "Event title is required"
    temp.streamKey = eventValues.streamKey &&  eventValues.streamKey.length !== 0 && eventValues.streamKey !== "" && eventValues.streamKey !== null ? "" : "Stream key from streaming provider is required"
    temp.venueTickets = eventValues.venueTickets &&  eventValues.venueTickets.length !== 0 && eventValues.venueTickets !== "" && eventValues.venueTickets !== null && isValidUrl(eventValues.venueTickets) ? "" : "Valid venue pre-sales ticket URL is required"
    temp.image = eventValues.image &&  eventValues.image.length !== 0 && eventValues.image !== "" && eventValues.image !== null && isValidUrl(eventValues.image) && validImage ? "" : "Valid image URL is required"
    temp.price = eventValues.price &&  eventValues.price.length !== 0 && eventValues.price !== "" && eventValues.price !== null ? "" : "PPV price is required"
    temp.startTime = eventValues.startTime &&  eventValues.startTime.length !== 0 && eventValues.startTime !== "" && eventValues.startTime !== null ? "" : "Start time is required"
    temp.venueName = eventValues.venueName &&  eventValues.venueName.length !== 0 && eventValues.venueName !== "" && eventValues.venueName !== null ? "" : "Venue name is required"
    temp.venueStreetAddress = eventValues.venueStreetAddress &&  eventValues.venueStreetAddress.length !== 0 && eventValues.venueStreetAddress !== "" && eventValues.venueStreetAddress !== null ? "" : "Venue street address is required"
    temp.venueCity = eventValues.venueCity &&  eventValues.venueCity.length !== 0 && eventValues.venueCity !== "" && eventValues.venueCity !== null ? "" : "Venue city is required"
    temp.venueState = eventValues.venueState &&  eventValues.venueState.length !== 0 && eventValues.venueState !== "" && eventValues.venueState !== null ? "" : "Venue state is required"
    temp.venueZip = eventValues.venueZip &&  eventValues.venueZip.length !== 0 && eventValues.venueZip !== "" && eventValues.venueZip !== null ? "" : "Venue zipcode is required"

    setEventErrors({
      ...temp
    })

    return Object.values(temp).every(x => x == "")
  }
  
  const updateFighterStatus = (event) => {

    if (allowsFighters === false) {
      setAllowsFighters(true)
//      console.log(true)
    }
    else {
      setAllowsFighters(false)
//      console.log(false)
    }

  };
    
    const updateIsActive = (event) => {

    if (isActive === false) {
      setIsActive(true)
//      console.log(true)
    }
    else {
      setIsActive(false)
//      console.log(false)
    }

  };
    
  const handleAddressChange = (place) => {
    formattedAddress = place.formatted_address;

    setVenueFullAddress(formattedAddress)  

  };
    
//    console.log(isActive)
    
  const handleStartTimeChange = (newValue) => {
      startTime = newValue;

      if (setEventValues({
          ...eventValues,
          startTime: newValue,
      })) {
      eventValidate()
      
//      console.log(values.dob + " Updated from handleDobChange" )
    }
      
  };
    
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
      transform: 'translateX(16px)',
    color: '#fff',
    '& + .MuiSwitch-track': {
      backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
      opacity: 1,
      border: 0,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
    color: '#33cf4d',
    border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
    opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
    },
    '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    },
    '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
    duration: 500,
    }),
    },
  }));
    
  const handleAddChange = (event) => {
    
    if (setEventValues({
      ...eventValues,
      [event.target.name]: event.target.value,
    })) {
      eventValidate()
      console.log(eventErrors)
    }

  };
 
  const handleClick = () => {
    setOpen(true);
  };
 
  const handleAddClick = () => {
//      setUuid(short.generate());
      
    if (!recording) {
        setRecording(uuid);
    }
      
      if (location && location.state) {
        navigate("/", {}); 
          
        setEventValues({
            isActive: true,
            isTest: false,
            isDelayed: false,
            delayedDate: null,
            eventTitle: null,
            eventSubtitle: null,
            streamProvider: 'Streaming Video Provider',
            streamKey: null,
            venueTickets: null,
            image: null,
            price: null,
            timezone: 'America/Los_Angeles',
            startTime: null,
            venueName: null,
            venueAddress: null,
            venueStreetAddress: null,
            venueSuite: "",
            venueCity: null,
            venueState: null,
            venueZip: null,
            video: "external",
            doorsOpen: 0,
            kidsAge: 8,
            ticketsGeneral: null,
            ticketsVIP: null,
            ticketsTable: null,
            ticketsKids: null,
        })
      } 
      
      setAddOpen(true);
    
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
    
  const handleAddClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    document.getElementById('add-event').reset();
      
      if (location && location.state) {
        navigate("/", {});
          
        setUuid(null);
        setRecording(null);
        setIsActive(true);
          
        setEventValues({
            isActive: true,
            isTest: false,
            isDelayed: false,
            delayedDate: null,
            eventTitle: null,
            eventSubtitle: null,
            streamProvider: 'Streaming Video Provider',
            streamKey: null,
            venueTickets: null,
            image: null,
            price: null,
            timezone: 'America/Los_Angeles',
            startTime: null,
            venueName: null,
            venueAddress: null,
            venueStreetAddress: null,
            venueSuite: "",
            venueCity: null,
            venueState: null,
            venueZip: null,
            video: "external",
            doorsOpen: 0,
            kidsAge: 8,
            ticketsGeneral: null,
            ticketsVIP: null,
            ticketsTable: null,
            ticketsKids: null,
        })
      } 
      
      setAddOpen(false);
  };
 
  const handleAddSubmit = (event) => {
      
    event.preventDefault();
      
    if (eventValues.venueSuite && eventValues.venueSuite !== "" && eventValues.venueSuite !== null) {
        fullVenueAddress = eventValues.venueStreetAddress + " " + eventValues.venueSuite + ", " + eventValues.venueCity + ", " + eventValues.venueState + " " + eventValues.venueZip;
    }
    else {
        fullVenueAddress = eventValues.venueStreetAddress + ", " + eventValues.venueCity + ", " + eventValues.venueState + " " + eventValues.venueZip;
    }
      
    if (eventValues.eventSubtitle && eventValues.eventSubtitle !== "" && eventValues.eventSubtitle !== null) {
        eventName = eventValues.eventTitle + " (" + eventValues.eventSubtitle + ")";
    }
    else {
        eventName = eventValues.eventTitle 
    }

    if (eventValidate()) {
      
        addNewEvent()
      
//        console.log(
//            'id: ' + uuid + ', ' +
//            ' recording: ' + uuid + ', ' +
//            ' isActive: ' + eventValues.isActive + ', ' +
//            ' isTest: ' + eventValues.isTest + ', ' +
//            ' isDelayed: ' + eventValues.isDelayed + ', ' +
//            ' delayedDate: ' + eventValues.delayedDate + ', ' +
//            ' name: ' + eventName + ', ' +
//            ' streamProvider: ' + eventValues.streamProvider + ', ' +
//            ' streamKey: ' + eventValues.streamKey + ', ' +
//            ' venueTickets ' + eventValues.venueTickets + ', ' +
//            ' image: ' + eventValues.image + ', ' +
//            ' price: ' + `$${eventValues.price}` + ', ' +
//            ' timezone: ' + eventValues.timezone + ', ' +
//            ' startTime: ' + moment.tz(eventValues.startTime, eventValues.timezone).format() + ', ' +
//            ' endTime: ' + moment.tz(Moment(eventValues.startTime).format('ll') + ' 23:59', eventValues.timezone).format() + ', ' +
//            ' venueName: ' + eventValues.venueName + ', ' +
//            ' venueAddress: ' + fullVenueAddress + ', ' +
//            ' allowsFighters: ' + allowsFighters + ', ' +
//            ' video: ' + eventValues.video + ', ' +
//            ' doorsOpen: ' + eventValues.doorsOpen + ', ' +
//            ' kidsAge: ' + eventValues.kidsAge + ', ' +
//            ' ticketsGeneral: ' + eventValues.ticketsGeneral + ', ' +
//            ' ticketsVIP: ' + eventValues.ticketsVIP + ', ' +
//            ' ticketsTable: ' + eventValues.ticketsTable + ', ' +
//            ' ticketsKids: ' + eventValues.ticketsKids
//        )    
    }


  async function addNewEvent() {
      const updatingUser = await fetch (`${server}events/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: uuid,
            recording: recording,
            isActive: isActive,
            isTest: eventValues.isTest,
            isDelayed: eventValues.isDelayed,
            delayedDate: eventValues.delayedDate,
            name: eventName,
            streamProvider: eventValues.streamProvider,
            streamKey: eventValues.streamKey,
            venueTickets: eventValues.venueTickets,
            image: eventValues.image,
            price: `$${eventValues.price}`,
            timezone: eventValues.timezone,
            startTime: moment.tz(eventValues.startTime, eventValues.timezone).format(),
            endTime: moment.tz(Moment(eventValues.startTime).format('YYYY-MM-DD') + ' 23:59', eventValues.timezone).format(),
            venueName: eventValues.venueName,
            venueAddress: fullVenueAddress,
            allowsFighters: allowsFighters,
            video: eventValues.video,
            doorsOpen: eventValues.doorsOpen,
            kidsAge: eventValues.kidsAge,
            ticketsGeneral: eventValues.ticketsGeneral,
            ticketsVIP: eventValues.ticketsVIP,
            ticketsTable: eventValues.ticketsTable,
            ticketsKids: eventValues.ticketsKids,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('add-event').reset();
        
       
      if (location && location.state) {
        navigate("/", {});
          
       setUuid(null);
       setRecording(null);
       setIsActive(true);
          
        setEventValues({
            isActive: true,
            isTest: false,
            isDelayed: false,
            delayedDate: null,
            eventTitle: null,
            eventSubtitle: null,
            streamProvider: 'Streaming Video Provider',
            streamKey: null,
            venueTickets: null,
            image: null,
            price: null,
            timezone: 'America/Los_Angeles',
            startTime: null,
            venueName: null,
            venueAddress: null,
            venueStreetAddress: null,
            venueSuite: "",
            venueCity: null,
            venueState: null,
            venueZip: null,
            video: "external",
            doorsOpen: 0,
            kidsAge: 8,
            ticketsGeneral: null,
            ticketsVIP: null,
            ticketsTable: null,
            ticketsKids: null,
        })
      } 
          
        window.location.assign("/");
//        getUser();
//        console.log("Got user")
          
        setAddOpen(false);
        // setProfile(data);
      })
      .catch((error) => {
        console.error ('Error:', error);
      });
    }
  };
    
  var addEvent = '';
  var searchFighters = '';
// console.log(Moment(eventValues.startTime).format('YYYY-MM-DD'))
// console.log(moment.tz(Moment(eventValues.startTime).format('YYYY-MM-DD') + ' 23:59', eventValues.timezone).format())   
 const stateOptions = [
  { value: "AL", label: "AL - Alabama" },
  { value: "AK", label: "AK - Alaska" },
  { value: "AZ", label: "AZ - Arizona" },
  { value: "AR", label: "AR - Arkansas" },
  { value: "CA", label: "CA - California" },
  { value: "CO", label: "CO - Colorado" },
  { value: "CT", label: "CT - Connecticut" },
  { value: "DE", label: "DE - Delaware" },
  { value: "DC", label: "DC - District Of Columbia" },
  { value: "FL", label: "FL - Florida" },
  { value: "GA", label: "GA - Georgia" },
  { value: "HI", label: "HI - Hawaii" },
  { value: "ID", label: "ID - Idaho" },
  { value: "IL", label: "IL - Illinois" },
  { value: "IN", label: "IN - Indiana" },
  { value: "IA", label: "IA - Iowa" },
  { value: "KS", label: "KS - Kansas" },
  { value: "KY", label: "KY - Kentucky" },
  { value: "LA", label: "LA - Louisiana" },
  { value: "ME", label: "ME - Maine" },
  { value: "MD", label: "MD - Maryland" },
  { value: "MA", label: "MA - Massachusetts" },
  { value: "MI", label: "MI - Michigan" },
  { value: "MN", label: "MN - Minnesota" },
  { value: "MS", label: "MS - Mississippi" },
  { value: "MO", label: "MO - Missouri" },
  { value: "MT", label: "MT - Montana" },
  { value: "NE", label: "NE - Nebraska" },
  { value: "NV", label: "NV - Nevada" },
  { value: "NH", label: "NH - New Hampshire" },
  { value: "NJ", label: "NJ - New Jersey" },
  { value: "NM", label: "NM - New Mexico" },
  { value: "NY", label: "NY - New York" },
  { value: "NC", label: "NC - North Carolina" },
  { value: "ND", label: "ND - North Dakota" },
  { value: "OH", label: "OH - Ohio" },
  { value: "OK", label: "OK - Oklahoma" },
  { value: "OR", label: "OR - Oregon" },
  { value: "PA", label: "PA - Pennsylvania" },
  { value: "PR", label: "PR - Puerto Rico" },
  { value: "RI", label: "RI - Rhode Island" },
  { value: "SC", label: "SC - South Carolina" },
  { value: "SD", label: "SD - South Dakota" },
  { value: "TN", label: "TN - Tennessee" },
  { value: "TX", label: "TX - Texas" },
  { value: "UT", label: "UT - Utah" },
  { value: "VT", label: "VT - Vermont" },
  { value: "VI", label: "VI - Virgin Islands" },
  { value: "VA", label: "VA - Virginia" },
  { value: "WA", label: "WA - Washington" },
  { value: "WV", label: "WV - West Virginia" },
  { value: "WI", label: "WI - Wisconsin" },
  { value: "WY", label: "WY - Wyoming" }
];
    
    console.log(uuid, recording)
    
    
 if (isAuthenticated && profile?.role === "admin") {
     addEvent = 
           <Fab size="medium" color="primary" aria-label="Add event" onClick={handleAddClick}>
                <AddIcon />
            </Fab>
      searchFighters = 
            <Link to="search">
           <Fab size="medium" color="secondary" aria-label="Search Fighters">
                <SearchIcon />
            </Fab>
            </Link>
 }
     
 const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (addOpen && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [addOpen]);
     
//  console.log(eventValues.startTime)
     
  var eventForm =
  <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { m: 1, width: '25ch' },
    }}
    autoComplete="off"
    id="add-event"
  >
    <Dialog
     disableRestoreFocus
      fullScreen={fullScreen}
      open={addOpen}
      // onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Box
          sx={{
            display: 'flex',
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            className="subtitle"
          >
            Create New Event
          </Box>
          <CloseRoundedIcon onClick={handleAddClose} className="close-button" />
        </Box>
        <Box
        >
          {eventValues.eventTitle ?? ''}
          {eventValues.eventSubtitle ? <span className="event-subtitle"> ({eventValues.eventSubtitle})</span> : ''}
        </Box>
       <Box
        sx={{
            fontSize: '12px',
            color: 'red'
        }}
        >
        {(eventValues.image && validImage ? <img className="form-image-presenter" src={eventValues.image} /> : (eventValues.image && eventValues.image !== '' ? <div>Invalid Image URL</div> : ''))}
      </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>

        </DialogContentText>  
        <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} />}
            sx={{
                marginBottom: '16px',    
            }}
            name="isActive"
            checked={isActive === false ? false : true}
            label="Event is Active"
            onChange={updateIsActive}
        />
        <TextField
//          autoFocus
          inputRef={inputRef}
          required
          margin="normal"
          id="eventTitle"
          label="Event Title"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="eventTitle"
          defaultValue={eventValues.eventTitle ?? ''}
          onChange={handleAddChange}
          onBlur={handleAddChange}
          placeholder="Arte Suave Elite XXXIV"
          error={eventErrors.eventTitle || eventValues.eventTitle === '' ? true : false}
          helperText={eventErrors.eventTitle}
        />
        <TextField
          margin="normal"
          id="eventSubtitle"
          label="Event Subtitle (optional)"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="eventSubtitle"
          defaultValue={eventValues.eventSubtitle ?? ''}
          onChange={handleAddChange}
          placeholder="Havana Nights"
        />
        <TextField
            required
            disabled
            placeholder="Select..."
            margin="normal"
            size="normal"
            id="streamProvider"
            select
            fullWidth
            label="Stream Provider"
            name="streamProvider"
            defaultValue={eventValues.streamProvider ?? 'Streaming Video Provider'}
            onChange={handleAddChange}
            onBlur={handleAddChange}
            error={eventErrors.streamProvider || eventValues.streamProvider === '' ? true : false}
            helperText={eventErrors.streamProvider}
            sx={{
              display: 'none'                                                                                                                   
            }}
        >
            <MenuItem value={'Streaming Video Provider'}>Streaming Video Provider</MenuItem>
            <MenuItem value={'youtube'}>YouTube</MenuItem>
        </TextField>
        <TextField
          required
          margin="normal"
          id="streamKey"
          label="Stream Key"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="streamKey"
          defaultValue={eventValues.streamKey ?? ''}
          onChange={handleAddChange}
          onBlur={handleAddChange}
          placeholder="7s4o56gyefc4"
          error={eventErrors.streamKey || eventValues.streamKey === '' ? true : false}
          helperText={eventErrors.streamKey ?? "Provide the key generated after creating the livestream event"}
        />
        <TextField
          required
          margin="normal"
          id="venueTickets"
          label="Venue Tickets URL"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="venueTickets"
          defaultValue={eventValues.venueTickets ?? ''}
          onChange={handleAddChange}
          onBlur={handleAddChange}
          placeholder="https://brushfire.com/artesuaveelite/######"
          error={eventErrors.venueTickets || eventValues.venueTickets === '' ? true : false}
          helperText={eventErrors.venueTickets ?? "Provide the URL for venue pre-sales tickets after creating the purchasing event"}
        />
        <TextField
          required
          margin="normal"
          id="image"
          label="Image URL"
          type="text"
          fullWidth
          variant="outlined"
          size="normal"
          name="image"
          defaultValue={eventValues.image ?? ''}
          onChange={handleAddChange}
          onBlur={handleAddChange}
          placeholder="https://webaddress.com/myimage"
          error={eventErrors.image || eventValues.image === '' ? true : false}
          helperText={eventErrors.image ?? "Provide the URL for event banner image"}
        />
        <TextField
          required
          margin="normal"
          id="price"
          label="PPV Price"
          type="number"
          fullWidth
          variant="outlined"
          size="normal"
          name="price"
          defaultValue={eventValues.price ?? ''}
          onChange={handleAddChange}
          onBlur={handleAddChange}
          placeholder="20"
          error={eventErrors.price || eventValues.price === '' ? true : false}
          helperText={eventErrors.price}
          InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <TextField
            required
            placeholder="Select..."
            margin="normal"
            size="normal"
            id="timezone"
            select
            fullWidth
            label="Time Zone"
            name="timezone"
            defaultValue={eventValues.timezone ?? 'America/Los_Angeles'}
            onChange={handleAddChange}
            error={eventErrors.timezone || eventValues.timezone === '' ? true : false}
            helperText={eventErrors.timezone ?? 'This is the time zone of the event venue'}
        >
            {allTimeZones.map(function(data) {
              return (
                <MenuItem value={data}>{data}</MenuItem>
              )
            })}
        </TextField>
        <FormControl
            fullWidth
            variant="outlined"
            margin="normal"
            required
          >
            <DateTimePicker 
                label="Event Start Time*"
                type="text"
                disablePast
                placeholder="MM/DD/YYYY"
                defaultValue={eventValues.startTime && Moment(eventValues.startTime) && eventValues.startTime !== '' ? Moment(eventValues.startTime) : defaultDate} 
                views={['year', 'month', 'day', 'hours', 'minutes']} 
                error={eventErrors.startTime || eventValues.startTime === '' ? true : false} 
                name="startTime" 
                id="startTime" 
                helpertext={eventErrors.startTime ?? 'Should be in the event timezone'}  
                onChange={ (newValue) => handleStartTimeChange(newValue)} 
                slotProps={{
                    textField: {
                      helperText: eventErrors.startTime ? eventErrors.startTime : 'Date & time should match to the event timezone and cannot be in the past',
                    },
                }}
//                viewRenderers={{
//                    hours: renderTimeViewClock,
//                    minutes: renderTimeViewClock,
//                    seconds: renderTimeViewClock,
//                }}
//                sx={{
//                    '&.MuiClock-pin': { backgroundColor:'#6494aa !important' },
//                    '&.MuiClockPointer-root': { backgroundColor:'#6494aa !important' },
//                    '&.MuiClockPointer-thumb': { backgroundColor:'#6494aa !important', borderColor:'#6494aa !important' },
//                }}
            />
          </FormControl>
          <TextField
              required
              margin="normal"
              id="venueName"
              label="Venue Name"
              type="text"
              fullWidth
              variant="outlined"
              size="normal"
              name="venueName"
              defaultValue={eventValues.venueName ?? ''}
              onChange={handleAddChange}
              onBlur={handleAddChange}
              placeholder="Track 7"
              error={eventErrors.venueName || eventValues.venueName === '' ? true : false}
              helperText={eventErrors.venueName}
          />
          
          <TextField
              required
              margin="normal"
              id="venueStreetAddress"
              label="Venue Street Address"
              type="text"
              fullWidth
              variant="outlined"
              size="normal"
              name="venueStreetAddress"
              defaultValue={eventValues.venueStreetAddress ?? ''}
              onChange={handleAddChange}
              onBlur={handleAddChange}
              placeholder="123 Main St."
              error={eventErrors.venueStreetAddress || eventValues.venueStreetAddress === '' ? true : false}
              helperText={eventErrors.venueStreetAddress}
          />
          <TextField
              required
              margin="normal"
              id="venueCity"
              label="Venue City"
              type="text"
              fullWidth
              variant="outlined"
              size="normal"
              name="venueCity"
              defaultValue={eventValues.venueCity ?? ''}
              onChange={handleAddChange}
              onBlur={handleAddChange}
              placeholder="Sacramento"
              error={eventErrors.venueCity || eventValues.venueCity === '' ? true : false}
              helperText={eventErrors.venueCity}
          />
          <TextField
            required
            placeholder="Select..."
            margin="normal"
            size="normal"
            id="venueState"
            select
            fullWidth
            label="Venue State"
            name="venueState"
            defaultValue={eventValues.venueState ?? ''}
            onChange={handleAddChange}
            onBlur={handleAddChange}
            error={eventErrors.venueState || eventValues.venueState === '' ? true : false}
            helperText={eventErrors.venueState}
        >
            {stateOptions.map(function(data) {
              return (
                <MenuItem value={data.value}>{data.label}</MenuItem>
              )
            })}
        </TextField>
          <TextField
              required
              margin="normal"
              id="venueZip"
              label="Venue Zipcode"
              type="number"
              fullWidth
              variant="outlined"
              size="normal"
              name="venueZip"
              defaultValue={eventValues.venueZip ?? ''}
              onChange={handleAddChange}
              onBlur={handleAddChange}
              placeholder="95843"
              error={eventErrors.venueZip || eventValues.venueZip === '' ? true : false}
              helperText={eventErrors.venueZip}
          />

        <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} />}
            sx={{
                marginTop: '16px',    
            }}
            name="allowsFighters"
            checked={allowsFighters === false ? false : true}
            label="Allow fighter applications"
            onChange={updateFighterStatus}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          m: 'auto',
          width: 'fit-content',
        }}
      >
        <button onClick={handleAddSubmit} type="submit" className="cta" value="Submit" autoFocus>
            {location && location.state ? "Update Event" : "Create Event"}
        </button>
      </DialogActions>
    </Dialog>
  </Box>;


  return (
      <div className="App-cover hero">
        <div className="App-container">
          <h6><span className="art-text">Arte </span><span className="art-text">Suave </span><span className="art-text">Elite </span><span className="art-text">Network</span></h6>
          <div className="presents">Presents...</div>

          <div className="buy-list">
            <FutureEvents />
          </div>
            <a className="past-events cta" href="https://www.youtube.com/channel/UC1w5OhnKag2-NgFY8S_gr4w" target="_blank"><button className="tertiary">Looking for past events?</button></a>
            <div className="admin-actions">
                <Tooltip title="Add event" placement="top"
                    slotProps={{
                        popper: {
                        modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    },
                  }}
                >
                {addEvent}
                </Tooltip>
                <Tooltip title="Search fighters" placement="top"
                    slotProps={{
                        popper: {
                        modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    },
                  }}
                >
                {searchFighters}
                </Tooltip>
            </div>
            {eventForm}
        </div>
      </div>
  );
};

export default Home;

//{eventCount === 0 ? 
//                <div className="buy-card no-events">
//                  <div className="icon"><LocalActivityIcon fontSize="large" /></div>
//                  <div className="details">
//                    <div className="info">
//                        <div className="event_name">No Upcoming Events</div>
//                      </div>
//                    <div className="purchase-options">
//                        <a className="cta" href="mailto:info@artesuaveelite.com" target="_blank"><button>Request Event</button></a>
//                    </div>
//                  </div>
//
//                </div>
//                : <FutureEvents/ >
//            }

//            {eventCount > 0 ? <div className="presents">Presents...</div> : ""}

// <Collapse in={open}>
//   <Link to="/profile?edit=true">
//   <Snackbar
//       anchorOrigin={{ vertical, horizontal }}
//       open={open}
//       onClose={handleClose}
//       message="I love snacks"
//       key={vertical + horizontal}
//       TransitionComponent={Transition}
//       autoHideDuration={8000}
//       sx={{
//         // marginBottom: 5,
//         marginTop: "55px",
//         '@media screen and (max-width: 600px) ': {
//           marginTop: "75px",
//           '&:before': {
//             right: 54,
//           }
//         }
//       }}
//     >
//       <Alert
//         severity="info"
//         color="error"
//         iconMapping={{
//           info: <SportsKabaddiIcon fontSize="inherit" />,
//         }}
//         sx={{
//           bgcolor: 'background.paper',
//           color: '#E36B58',
//           fontWeight: 'bold',
//           textAlign: 'center',
//           textTransform: 'none',
//           boxShadow: 3,
//           overflow: 'visible',
//           '&:before': {
//             content: '""',
//             display: 'block',
//             position: 'absolute',
//             top: 0,
//             right: 14,
//             width: 10,
//             height: 10,
//             bgcolor: 'background.paper',
//             transform: 'translateY(-50%) rotate(45deg)',
//             zIndex: 0,
//             '@media screen and (max-width: 600px) ': {
//               right: 32,
//             }
//           }
//         }}
//         // action={
//         //   <IconButton
//         //     aria-label="close"
//         //     color="inherit"
//         //     size="small"
//         //     onClick={() => {
//         //       setOpen(false);
//         //     }}
//         //   >
//         //     <CloseIcon fontSize="inherit" />
//         //   </IconButton>
//         // }
//       >
//       Fighter profiles are now available. Create yours!
//       </Alert>
//     </Snackbar>
//   </Link>
// </Collapse>


//required
//margin="normal"
//id="venueAddress"
//label="Venue Address"
//type="text"
//fullWidth
//variant="outlined"
//size="normal"
//name="venueAddress"



//         <FormControl
//            fullWidth
//            variant="outlined"
//            margin="normal"
//            required
//            label="Venue Address*"
//            className="address-autocomplete"
//          >
//            <InputLabel htmlFor="venueAddress" error={eventErrors.venueAddress ? true : false}>Venue Address</InputLabel>
//            <Autocomplete
//              placeholder="Venue Address*"
//              required
//              name="venueAddress"
//              error={eventErrors.venueAddress || eventValues.venueAddress === '' ? true : false}
//              helperText={eventErrors.venueAddress}
//              apiKey={'AIzaSyAFoB2FmWStAt95nozDbCaHH3NGXdYlsnI'}
//              onPlaceSelected={(place) => {
//                handleAddressChange(place);
//              }}
//              options={{
//                types: ["geocode", "establishment"],
//                fields: ["place_id", "formatted_address"],
//                componentRestrictions: { country: "us" },
//              }}
//            />
//         </FormControl>
//
//          <TextField
//              margin="normal"
//              id="venueSuite"
//              label="Venue suite#, etc. (optional)"
//              type="text"
//              fullWidth
//              variant="outlined"
//              size="normal"
//              name="venueSuite"
//              defaultValue={eventValues.venueSuite ?? ''}
//              onChange={handleAddChange}
//              onBlur={handleAddChange}
//              placeholder="#100"
//          />
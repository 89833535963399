import React, { useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'moment';

import { useRef } from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import CircleIcon from '@mui/icons-material/Circle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DatePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import UserContext from '../components/user-data';

//  var server = '/api';    
var server = 'https://ase-server.vercel.app/api/';


const Profile = () => {

  const { user, logout, isAuthenticated, loginWithRedirect } = useAuth0()
  const profile  = useContext(UserContext); 
    
  //Edit Modal
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

//  const queryParams = new URLSearchParams(window.location.search)
//  const edit = queryParams.get("edit")
    
    
  console.log(profile)
  
  const [fighterStatus, setFighterStatus] = React.useState();

  const [values, setValues] = React.useState({
    firstName: null,
    lastName: null,
    phone: null,
    location: null,
    instagram: null,
    dob: null,
    gender: null,
    weight: null,
    gym: null,
    belt: null,
    training: null,
    matchPref: null,
    style: null,
  });
    
    
const stateOptions = [
  { value: "AL", label: "AL - Alabama" },
  { value: "AK", label: "AK - Alaska" },
  { value: "AZ", label: "AZ - Arizona" },
  { value: "AR", label: "AR - Arkansas" },
  { value: "CA", label: "CA - California" },
  { value: "CO", label: "CO - Colorado" },
  { value: "CT", label: "CT - Connecticut" },
  { value: "DE", label: "DE - Delaware" },
  { value: "DC", label: "DC - District Of Columbia" },
  { value: "FL", label: "FL - Florida" },
  { value: "GA", label: "GA - Georgia" },
  { value: "HI", label: "HI - Hawaii" },
  { value: "ID", label: "ID - Idaho" },
  { value: "IL", label: "IL - Illinois" },
  { value: "IN", label: "IN - Indiana" },
  { value: "IA", label: "IA - Iowa" },
  { value: "KS", label: "KS - Kansas" },
  { value: "KY", label: "KY - Kentucky" },
  { value: "LA", label: "LA - Louisiana" },
  { value: "ME", label: "ME - Maine" },
  { value: "MD", label: "MD - Maryland" },
  { value: "MA", label: "MA - Massachusetts" },
  { value: "MI", label: "MI - Michigan" },
  { value: "MN", label: "MN - Minnesota" },
  { value: "MS", label: "MS - Mississippi" },
  { value: "MO", label: "MO - Missouri" },
  { value: "MT", label: "MT - Montana" },
  { value: "NE", label: "NE - Nebraska" },
  { value: "NV", label: "NV - Nevada" },
  { value: "NH", label: "NH - New Hampshire" },
  { value: "NJ", label: "NJ - New Jersey" },
  { value: "NM", label: "NM - New Mexico" },
  { value: "NY", label: "NY - New York" },
  { value: "NC", label: "NC - North Carolina" },
  { value: "ND", label: "ND - North Dakota" },
  { value: "OH", label: "OH - Ohio" },
  { value: "OK", label: "OK - Oklahoma" },
  { value: "OR", label: "OR - Oregon" },
  { value: "PA", label: "PA - Pennsylvania" },
  { value: "PR", label: "PR - Puerto Rico" },
  { value: "RI", label: "RI - Rhode Island" },
  { value: "SC", label: "SC - South Carolina" },
  { value: "SD", label: "SD - South Dakota" },
  { value: "TN", label: "TN - Tennessee" },
  { value: "TX", label: "TX - Texas" },
  { value: "UT", label: "UT - Utah" },
  { value: "VT", label: "VT - Vermont" },
  { value: "VI", label: "VI - Virgin Islands" },
  { value: "VA", label: "VA - Virginia" },
  { value: "WA", label: "WA - Washington" },
  { value: "WV", label: "WV - West Virginia" },
  { value: "WI", label: "WI - Wisconsin" },
  { value: "WY", label: "WY - Wyoming" }
];
  
//  if (values.dob === null){
//      console.log(values.dob + " Updated from default" )
//  }
  

  // const handleEdit = () => {
  //   setOpen(true);
  //   setFighterStatus(true)
  // };

  const handleClickOpen = () => {
    setOpen(true);
    
    if (isFighter === true) {
     setFighterStatus(true)
    }
  };

  const handleSpecialClickOpen = () => {
    setOpen(true);
    setFighterStatus(true)
  };

  const handleClose = () => {
    setOpen(false);
    setFighterStatus(isFighter)
    setValues({
      phone: phone,
      dob: dob,
      training: trainingSince,
    });
    document.getElementById('edit-profile').reset();
//    console.log(values.dob + " Updated from handleClose" )
//    window.location.assign("/profile");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      updateUser()
//      console.log("Updated User");
    }
    // console.log(values);

    async function updateUser() {
      const updatingUser = await fetch (`${server}users/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userEmail,
          isFighter: fighterStatus,
          firstName: values.firstName,
          lastName: values.lastName,
          instagram: values.instagram,
          phone: values.phone,
          location: values.locationCity + ", "  + values.locationState,
          dob: values.dob,
          gender: values.gender,
          weight: values.weight,
          beltRank: values.belt,
          gymAffiliation: values.gym,
          trainingSince: values.training,
          matchPref: values.matchPref,
          style: values.style,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('edit-profile').reset();
        window.location.assign("/profile");
          
        setOpen(false);
//        getUser()
//        console.log("Got user")
        
        // setProfile(data);
      })
      .catch((error) => {
        console. error ('Error:', error);
      });
    }
  };

  //Edit Profile
  const ref = useRef(null);
  const inputRef = useRef(null);

  const PhoneMask = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(000) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });

  PhoneMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const DateMask = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0000-00-00"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });

  DateMask.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
      transform: 'translateX(16px)',
    color: '#fff',
    '& + .MuiSwitch-track': {
      backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
      opacity: 1,
      border: 0,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.5,
    },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
    color: '#33cf4d',
    border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
    opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
    },
    '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    },
    '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
    duration: 500,
    }),
    },
  }));

  //View Profile
  if (isAuthenticated === false) {
    // window.location = "/"
    loginWithRedirect()
  }


  // const [phoneNumber, setPhoneNumber] = React.useState();

  const userEmail = user.email;
  const userName = user.name;
  const userPicture = user.picture;
  var phoneURL = null;
  var locationURL = null;
  var fullname = userName;
  var splitName = fullname;
  var splitName2 = null;
  var firstName = user.name;
  var lastName = "";
  if (fullname.includes(" ")) {
    splitName = fullname.split(" ");
    firstName = splitName[0];
    lastName = splitName[1];
  }
  // else if (fullname.includes("@")) {
  //   splitName = fullname.split("@");
  //   firstName = splitName[0];
  //
  //   splitName2 = splitName[1].split(".");
  //   lastName = splitName2[0];
  // }
  // var phoneFormatted = null;

  var today = Moment().format('ll');

  var isFighter = false;


  if (isAuthenticated && profile && profile.fighter) {
    isFighter = profile.fighter.isActive === true ? true : false;

    if (profile.fighter.firstName !== undefined || profile.fighter.firstName !== null) {
      firstName = profile.fighter.firstName;
    }
    if (profile.fighter.lastName !== undefined || profile.fighter.lastName !== null) {
      lastName = profile.fighter.lastName;
    }
    fullname = firstName + " " + lastName;
    var instagram = profile.fighter.instagram;
    var phone = profile.fighter.phone;
    // phoneFormatted = "(" + phone[0] + phone[1] + phone[2]+ ") " + phone[3] + phone[4] + phone[5] + "-" + phone[6] + phone[7] + phone[8] + phone[9];

    var emailURL = `mailto:${userEmail}`;
    if (phone) {
      phoneURL = `tel:${phone.replace(/\D/g,'')}`;
    }
      
    var location = profile.fighter.location;
      
    if (location) {
        var splitAddress = location.split(",");
        var locationCity = splitAddress[0];
        var locationState = splitAddress[1].substring(1);
        
        //location = locationCity + ", " + locationState;

        locationURL = `https://www.google.com/maps/search/?api=1&query=${locationCity}+${locationState}`;
    }
    var instagramURL = `https://instagram.com/${instagram}`;
     
    // Fighter Details
    var dob = profile.fighter.dob;
    var age = Moment().diff(Moment(dob), "years");

    var gender = profile.fighter.gender;
    var weight = profile.fighter.weight;

    var belt = profile.fighter.beltRank;
    var beltImage = "media/images/belts/" + belt + ".svg";

    var gym = profile.fighter.gymAffiliation;

    var trainingSince = profile.fighter.trainingSince;
    var timeTraining = Moment().diff(Moment(trainingSince), "years", true);
    timeTraining = Math.round(timeTraining * 10) / 10

    var matchPref = profile.fighter.matchPref;
    var style = profile.fighter.style;
  }
  else {
      firstName = "";
      lastName = "";
  }

//  useEffect(() => {
//      else {
//          getUser()
//          console.log("Got user")
//      }
//  });
  if (userPicture) {
    var avatar = `<img class="profile-img" src=${userPicture} alt=${firstName} />`;
  }
  else {
    var avatar = `<div class="profile-initials">${firstName[0]}</div>`;
  }

  var emailContainer = `<div class="profile-label">Email</div><div><a href=${emailURL}>${userEmail}</a></div>`;
  if (phone) {
    var phoneContainer = `<div class="profile-label">Phone</div><div><a href=${phoneURL}>${phone}</a></div>`;
  }
  else {
    var phoneContainer = `<div class="profile-label">Phone</div><div>--</div>`;
  }
  if (location) {
    var locationContainer = `<div class="profile-label">Location</div><div><a href=${locationURL} target="_blank">${location}</a></div>`;
  }
  else {
    var locationContainer = `<div class="profile-label">Location</div><div>--</div>`;
  }
  if (instagram) {
    var instagramContainer = `<div class="profile-label">Instagram</div><div><a href=${instagramURL} target="_blank">@${instagram}</a></div>`;
  }
  else {
    var instagramContainer = `<div class="profile-label">Instagram</div><div>--</div>`;
  }

  if (isFighter == true) {
    if (gym) {
      var gymContainer = `<h3 class="gym">${gym}</h3>`;
    }
    else{
      var gymContainer = ``;
    }
    if (belt) {
      var beltContainer = `<img class="belt-img" src=${beltImage} alt=${belt} />`;
    }
    else{
      var beltContainer = ``;
    }
    if (dob) {
      var ageContainer = `<div class="profile-label">Age</div><div>${age}</div>`;
    }
    else {
      var ageContainer = `<div class="profile-label">Age</div><div>--</div>`;
    }
    if (gender) {
      var genderContainer = `<div class="profile-label">Gender</div><div class="capitalize">${gender}</div>`;
    }
    else {
      var genderContainer = `<div class="profile-label">Gender</div><div class="capitalize">--</div>`;
    }
    if (weight) {
      var weightContainer = `<div class="profile-label">Weight</div><div>${weight} lbs</div>`;
    }
    else {
      var weightContainer = `<div class="profile-label">Weight</div><div>--</div>`;
    }
    if (trainingSince) {
      var trainingContainer = `<div class="profile-label">Experience</div><div>${timeTraining} years</div>`;
    }
    else {
      var trainingContainer = `<div class="profile-label">Experience</div><div>--</div>`;
    }
    if (matchPref) {
      var matchContainer = `<div class="profile-label">Match Preference</div><div class="capitalize">${matchPref}</div>`;
    }
    else {
      var matchContainer = `<div class="profile-label">Match Preference</div><div class="capitalize">--</div>`;
    }
    if (style) {
      var styleContainer = `<div class="profile-label">Jiu Jitsu Style</div><div class="capitalize">${style}</div>`;
    }
    else {
      var styleContainer = `<div class="profile-label">Jiu Jitsu Style</div><div class="capitalize">--</div>`;
    }

    var profileItems = `<div class="profile-details">${emailContainer}</div><div class="profile-details">${phoneContainer}</div><div class="profile-details">${locationContainer}</div><div class="profile-details">${instagramContainer}</div><div class="profile-details">${ageContainer}</div><div class="profile-details">${genderContainer}</div><div class="profile-details">${weightContainer}</div><div class="profile-details">${trainingContainer}</div><div class="profile-details">${matchContainer}</div><div class="profile-details">${styleContainer}</div>`;
  }
  else {
    var profileItems = `<div class="profile-details">${emailContainer}</div><div class="profile-details">${phoneContainer}</div><div class="profile-details">${instagramContainer}</div>`;
  }

  useEffect(() => {
      
//     !values.firstName && values.firstName === null && firstName !== undefined && firstName !== '
      
    if (profile) {
        document.title = `ASEN - User Profile: ${fullname}`;
    }
    else {
        document.title = `ASEN - User Profile: ${userName}`;
    } 
      
    if (values.fighterStatus === false) {
      setFighterStatus(false)
    }
    else if (values.fighterStatus === true) {
        setFighterStatus(true)
    }
      
    if (
        ((!values.firstName || values.firstName === "" || values.firstName === undefined || values.firstName === null) && (firstName && firstName === "")) ||
        ((!values.lastName || values.lastName === "" || values.lastName === undefined || values.lastName === null) && (lastName && lastName === "")) ||
        ((!values.phone || values.phone === "" || values.phone === undefined || values.phone === null) && (phone && phone === "")) ||
        ((!values.locationCity || values.locationCity === "" || values.locationCity === undefined || values.locationCity === null) && (locationCity && locationCity === "")) ||
        ((!values.locationState || values.locationState === "" || values.locationState === undefined || values.locationState === null) && (locationState && locationState === "")) ||
        ((!values.instagram && instagram) && (instagram && values.instagram !== "")) ||
        (!values.dob && dob) ||
        (!values.gender && gender) ||
        ((!values.weight || values.weight === "" || values.weight === undefined || values.weight === null) && (weight && weight === "")) ||
        ((!values.gym || values.gym === "" || values.gym === undefined || values.gym === null) && (gym && gym === "")) ||
        (!values.belt && belt) ||
        (!values.training && trainingSince) ||
        (!values.matchPref && matchPref) ||
        (!values.style && style)

    ) {
      setValues({
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        locationCity: locationCity,
        locationState: locationState,
        instagram: instagram,
        dob: dob,
        gender: gender,
        weight: weight,
        gym: gym,
        belt: belt,
        training: trainingSince,
        matchPref: matchPref,
        style: style,
      });
    }
    
//    console.log(values.instagram + " Updated from no value set" )
  });



  const validate = () => {
    let temp= {}
    
    temp.firstName = values.firstName &&  values.firstName.length !== 0 && values.firstName !== "" && values.firstName !== null ? "" : "First name is required"
    temp.lastName = values.lastName && values.lastName.length !== 0 && values.lastName !== "" && values.lastName !== null ? "" : "Last name is required"

    if (fighterStatus === true) {
      temp.phone = values.phone.length > 13 ? "" : "Please enter a valid phone number"
      temp.locationCity = values.locationCity && values.locationCity.length !== 0 && values.locationCity !== "" && values.locationCity !== null ? "" : "City is required"
      
      if (values.locationState === '' || values.locationState === null || values.locationState === undefined) {
        temp.locationState = true
      }
      else {
        temp.locationState = values.locationState !== '' && values.locationState.length !== 0 ? "" : "Please select and option"
      }
        
      if(values.dob) {
        var splitDob = values.dob.split("-");
        var dob_year = splitDob[0];
        var dob_month = splitDob[1];
        var dob_day = splitDob[2];
      }
      if (values.dob === '' || values.dob === null || values.dob === undefined || dob_year === '' || dob_year === null || dob_year === undefined || dob_year < 1900 || dob_year > Moment().year() || dob_month === '' || dob_month === null || dob_month === undefined || dob_month < 1 || dob_month > 12 || dob_day === '' || dob_day === null || dob_day === undefined || dob_day < 1 || dob_day > 31) {
        temp.dob = "Please enter a valid date in MM/DD/YYYY format"
      }
      else {
        temp.dob = values.dob !== '' && values.dob.length > 9 ? "" : "Please enter a valid date in YYYY-MM-DD format"
      }
      if (values.gender === '' || values.gender === null || values.gender === undefined) {
        temp.gender = "Please select and option"
      }
      else {
        temp.gender = values.gender !== '' || values.gender.length !== 0 ? "" : "Please select and option"
      }
      if (values.weight === '' || values.weight === null || values.weight === undefined) {
        temp.weight = "Please enter your weight"
      }
      else {
        temp.weight = values.weight.length !== 0 && values.weight.length < 4 ? "" : "Please enter your weight"
      }
      if (values.gym === '' || values.gym === null || values.gym === undefined) {
        temp.gym = "Gym name is required"
      }
      else {
        temp.gym = values.gym !== '' && values.gym.length !== 0 ? "" : "Gym name is required"
      }
      if (isKid === true) {
        if (values.belt === '' || values.belt === null || values.belt === undefined) {
          temp.beltKid = true
        }
        else {
          temp.beltKid = values.belt !== '' && values.belt.length !== 0 ? "" : "Please select and option"
        }
      }
      else {
        if (values.belt === '' || values.belt === null || values.belt === undefined) {
          temp.beltAdult = true
        }
        else {
          temp.beltAdult = values.belt !== '' && values.belt.length !== 0 ? "" : "Please select and option"
        }
      }
      if(values.training) {
        var splitTraining = values.training.split("-");
        var training_year = splitTraining[0];
        var training_month = splitTraining[1];
        var training_day = splitTraining[2];
      }
      if (values.training === '' || values.training === null || values.training === undefined || training_year === '' || training_year === null || training_year === undefined || training_year < 1900 || training_year > Moment().year() || training_month === '' || training_month === null || training_month === undefined || training_month < 1 || training_month > 12 || training_day === '' || training_day === null || training_day === undefined || training_day < 1 || training_day > 31) {
        temp.training = true
      }
      else {
        temp.training = values.training != '' && values.training.length > 9 ? "" : "Please enter a valid date in MM/DD/YYYY format"
      }
      if (values.matchPref === '' || values.matchPref === null || values.matchPref === undefined) {
        temp.matchPref = true
      }
      else {
        temp.matchPref = values.matchPref != '' && values.matchPref.length != 0 ? "" : "Please select and option"
      }
      if (values.style === '' || values.style === null || values.style === undefined) {
        temp.style = true
      }
      else {
        temp.style = values.style !== '' && values.style.length !== 0 ? "" : "Please select and option"
      }
    }
    else {
      if (values.phone === '' || values.phone === null || values.phone === undefined) {
        temp.phone = 1 === 1 ? "" : ""
      }
      else {
        temp.phone = values.phone.length > 13 ? "" : "Please enter a valid phone number"
      }
    }

    setErrors({
      ...temp
    })

    return Object.values(temp).every(x => x == "")
  }

  const [errors, setErrors] = React.useState({});

  var checkDOB = Moment().diff(Moment(values.dob), "years");
  var isKid = false;

  if (checkDOB < 15) {
    isKid = true;
  }

  const handleDobChange = (newValue) => {
      dob = Moment(newValue).format('YYYY-MM-DD');

      if (setValues({
          ...values,
          dob: dob,
      })) {
      validate()
      
//      console.log(values.dob + " Updated from handleDobChange" )
    }
      
  };

  const handleTrainingChange = (newValue) => {
      trainingSince = Moment(newValue).format('YYYY-MM-DD');

      if (setValues({
          ...values,
          training: trainingSince,
      })) {
      validate()
      
//      console.log(values.dob + " Updated from handleDobChange" )
    }
      
  };

  const handleChange = (event) => {
    if (setValues({
      ...values,
      [event.target.name]: event.target.value,
    })) {
      validate()
      // console.log(values.firstName)
        console.log(values.instagram + " Updated from handleChange" )
    }

  };

  const updateStatus = (event) => {

    if (!fighterStatus || fighterStatus === false) {
      setFighterStatus(true)
//      console.log(true)
    }
    else {
      setFighterStatus(false)
//      console.log(false)
    }

  };

//  useEffect(() => {
//    if (edit === "true" && profile) {
//      setOpen(true);
//    }
//  });


// console.log(values.belt )

//  async function getUser() {
//    const fetchingUser = await fetch (`${server}users`, {
//      method: 'POST',
//      headers: {
//        'Content-Type': 'application/json',
//      },
//      body: JSON.stringify({
//        email: userEmail,
//      }),
//    })
//    .then((response) => response.json())
//    .then((data) => {
//
//      if (data && data.email != userEmail) {
//        console.error("Empty array")
//      }
//      else {
//        setProfile(data);
//
//      }
//    })
//    .catch((error) => {
//      console. error ('Error:', error);
//    });
//  }

  //Document meta


  var acceptSignup = true;

  if (isAuthenticated) {
      return (
        isAuthenticated && (
          <div className="App-cover">
            <div className="App-container">
              <div className="profile-card">
                <div className="profile-actions">
                  {user.email === userEmail ? <button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button> : <div></div>}
                  {user.email === userEmail && acceptSignup === true ? <button onClick={handleClickOpen}>Edit</button> : <div></div>}
                </div>
                <div className="profile-head">
                  <div dangerouslySetInnerHTML={{ __html: avatar }}></div>
                  <h2>{fullname}</h2>
                  <div dangerouslySetInnerHTML={{ __html: gymContainer }}></div>
                  <div dangerouslySetInnerHTML={{ __html: beltContainer }}></div>
                </div>
                <div className="profile-body" dangerouslySetInnerHTML={{ __html: profileItems }}></div>
                {user.email === userEmail && acceptSignup === true && isFighter === false ? <button className="cta" onClick={handleSpecialClickOpen}>Register as Fighter</button> : <div></div>}
              </div>
            </div>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              autoComplete="off"
              id="edit-profile"
            >
              <Dialog
                fullScreen={fullScreen}
                open={open}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  <Box
                    sx={{
                      display: 'flex',
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {"Edit Profile"}
                    <CloseRoundedIcon onClick={handleClose} className="close-button"/>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>

                  </DialogContentText>
                  <TextField
                    autoFocus
                    required
                    margin="normal"
                    id="firstName"
                    label="First Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="normal"
                    name="firstName"
                    defaultValue={(values.firstName ? values.firstName : (firstName ? firstName : ''))}
                    onBlur={event => handleChange(event)}
                    placeholder="John"
                    error={errors.firstName || values.firstName === '' ? true : false}
                    helperText={errors.firstName}
                  />
                  <TextField
                    required
                    margin="normal"
                    id="lastName"
                    label="Last Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="normal"
                    name="lastName"
                    defaultValue={(values.lastName ? values.lastName : (lastName ? lastName : ''))}
                    onBlur={event => handleChange(event)}
                    placeholder="Doe"
                    error={errors.lastName || values.lastName === '' ? true : false}
                    helperText={errors.lastName}
                  />
                  <TextField
                    required
                    disabled
                    margin="normal"
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    size="normal"
                    defaultValue={userEmail}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required={fighterStatus === true ? true : false}
                  >
                    <InputLabel htmlFor="phone" error={errors.phone ? true : false}>Phone</InputLabel>
                    <OutlinedInput
                      value={values.phone ?? ''}
                      onBlur={handleChange}
                      label="Phone"
                      name="phone"
                      id="phone"
                      type="text"
                      inputComponent={PhoneMask}
                      placeholder="(000) 000-0000"
                      error={errors.phone ? true : false}
                      helpertext={errors.phone}
                    />
                  </FormControl>
                {fighterStatus === true ?
                  <TextField
                      required
                      margin="normal"
                      id="locationCity"
                      label="City"
                      type="text"
                      fullWidth
                      variant="outlined"
                      size="normal"
                      name="locationCity"
                      defaultValue={values.locationCity ?? ''}
                      onChange={handleChange}
                      onBlur={handleChange}
                      placeholder="Sacramento"
                      error={errors.locationCity || values.locationCity === '' ? true : false}
                      helperText={errors.locationCity}
                  />
                :""}
                {fighterStatus === true ?
                  <TextField
                    required
                    placeholder="Select..."
                    margin="normal"
                    size="normal"
                    id="locationState"
                    select
                    fullWidth
                    label="State"
                    name="locationState"
                    defaultValue={values.locationState ?? ''}
                    onChange={handleChange}
                    onBlur={handleChange}
                    error={errors.locationState || values.locationState === '' ? true : false}
                    helperText={errors.locationState}
                  >
                    {stateOptions.map(function(data) {
                          return (
                            <MenuItem value={data.value}>{data.label}</MenuItem>
                          )
                        })
                    }
                  </TextField>
                : ""}
                  <TextField
                    margin="normal"
                    id="instagram"
                    label="Instagram"
                    placeholder="jiujitsu_player"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="normal"
                    name="instagram"
                    defaultValue={values.instagram ?? ''}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">@</InputAdornment>,
                    }}
                  />
                  <FormControlLabel
                    control={<IOSSwitch sx={{ margin: '0 8px 0 0' }} />}
                    sx={{
                        margin: '16px 0',    
                    }}
                    checked={fighterStatus === true ? true : false}
                    label="Register as Fighter"
                    onChange={updateStatus}
                  />
                  {fighterStatus === true ?
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                  >
                    
                    
                    <DatePicker 
                        label="Date of Birth*"
                        type="text"
                        placeholder="MM/DD/YYYY"
                        defaultValue={Moment(values.dob) ?? ''} 
                        views={['year', 'month', 'day']} 
                        error={errors.dob || values.dob === '' ? true : false} 
                        name="dob" id="dob" 
                        helpertext={errors.dob}  
                        onChange={ (newValue) => handleDobChange(newValue)}
                        slotProps={{
                            textField: {
                              helperText: errors.dob ? errors.dob : 'Should be in MM/DD/YYYY format',
                            },
                        }}
//                        onChange={(newValue) => console.log(Moment(newValue).format('YYYY-MM-DD') + " Updated from picker")} 
                    />
                    

                  </FormControl>
                  : <span></span>}
                  {fighterStatus === true ?
                  <TextField
                    required
                    placeholder="Select..."
                    margin="normal"
                    size="normal"
                    id="gender"
                    select
                    fullWidth
                    label="Gender"
                    name="gender"
                    value={values.gender ?? ''}
                    onChange={handleChange}
                    error={errors.gender || values.gender === '' ? true : false}
                    helperText={errors.gender}
                  >
                    <MenuItem value={''} disabled>Select...</MenuItem>
                    <MenuItem value={'male'}>Male</MenuItem>
                    <MenuItem value={'female'}>Female</MenuItem>
                  </TextField>
                  : <span></span>}
                  {fighterStatus === true ?
                  <TextField
                    required
                    margin="normal"
                    id="weight"
                    label="Weight"
                    placeholder="190"
                    type="number"
                    fullWidth
                    variant="outlined"
                    size="normal"
                    name="weight"
                    defaultValue={values.weight ?? ''}
                    onChange={handleChange}
                    error={errors.weight}
                    helperText={errors.weight || values.weight === '' ? true : false}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    }}
                  />
                  : <span></span>}
                  {fighterStatus === true ?
                  <TextField
                    required
                    margin="normal"
                    id="gym"
                    label="Gym Affiliation"
                    type="text"
                    fullWidth
                    variant="outlined"
                    size="normal"
                    name="gym"
                    defaultValue={values.gym ?? ''}
                    onChange={handleChange}
                    placeholder="Precision Jiu Jitsu Academy"
                    error={errors.gym || values.gym === '' ? true : false}
                    helperText={errors.gym}
                  />
                  : <span></span>}
                  {isKid === true && fighterStatus === true ?
                  <TextField
                    required
                    placeholder="Select..."
                    margin="normal"
                    size="normal"
                    id="belt"
                    select
                    fullWidth
                    label="Belt Rank"
                    name="belt"
                    defaultValue={values.belt && values.belt !== "" && values.belt !== null && values.belt !== undefined ? values.belt : 'white'}
                    onChange={handleChange}
                    error={errors.beltKid || values.beltKid === '' ? true : false}
                    helperText={errors.beltKid}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CircleIcon 
                                sx={
                                    (values.belt === "white" ? {color: "Gainsboro"} :
                                    (values.belt === "gray" ? {color: "DimGray"} :
                                    (values.belt === "yellow" ? {color: "Gold"} :
                                    (values.belt === "orange" ? {color: "Orange"} :
                                    (values.belt === "green" ? {color: "MediumSeaGreen"} :
                                    {color: "Gainsboro" })))))
                                } 
                          />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value={''} disabled>Select...</MenuItem>
                    <MenuItem value={'white'}>White</MenuItem>
                    <MenuItem value={'gray'}>Gray</MenuItem>
                    <MenuItem value={'yellow'}>Yellow</MenuItem>
                    <MenuItem value={'orange'}>Orange</MenuItem>
                    <MenuItem value={'green'}>Green</MenuItem>
                  </TextField>
                  : fighterStatus === true ?
                  <TextField
                    required
                    placeholder="Select..."
                    margin="normal"
                    size="normal"
                    id="belt"
                    select
                    fullWidth
                    label="Belt Rank"
                    name="belt"
                    defaultValue={values.belt ?? ''}
                    onChange={handleChange}
                    error={errors.beltAdult || values.beltAdult === '' ? true : false}
                    helperText={errors.beltAdult}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CircleIcon 
                                sx={
                                    (values.belt === "white" ? {color: "Gainsboro"} :
                                    (values.belt === "blue" ? {color: "DodgerBlue"} :
                                    (values.belt === "purple" ? {color: "MediumSlateBlue"} :
                                    (values.belt === "brown" ? {color: "Sienna"} :
                                    (values.belt === "black" ? {color: "Black"} :
                                    {color: "Gainsboro" })))))
                                } 
                          />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value={''} disabled>Select...</MenuItem>
                    <MenuItem value={'white'}>White</MenuItem>
                    <MenuItem value={'blue'}>Blue</MenuItem>
                    <MenuItem value={'purple'}>Purple</MenuItem>
                    <MenuItem value={'brown'}>Brown</MenuItem>
                    <MenuItem value={'black'}>Black</MenuItem>
                  </TextField>
                   : <span></span>}
                   {fighterStatus === true ?
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                  >
                    <DatePicker 
                        label="Training Since*"
                        type="text"
                        placeholder="MM/DD/YYYY"
                        defaultValue={Moment(values.training) ?? ''} 
                        views={['year', 'month', 'day']} 
                        error={errors.training || values.training === '' ? true : false} 
                        name="training" 
                        id="training" 
                        helpertext={errors.training}  
                        onChange={ (newValue) => handleTrainingChange(newValue)}
                        slotProps={{
                            textField: {
                              helperText: errors.training ? errors.training : 'Should be in MM/DD/YYYY format',
                            },
                        }}
//                      onChange={(newValue) => console.log(Moment(newValue).format('YYYY-MM-DD') + " Updated from picker")} 
                    />
                    
                  </FormControl>
                  : <span></span>}
                  {fighterStatus === true ?
                  <TextField
                    required
                    placeholder="Select..."
                    margin="normal"
                    size="normal"
                    id="matchPref"
                    select
                    fullWidth
                    label="Match Preference"
                    name="matchPref"
                    value={values.matchPref ?? ''}
                    onChange={handleChange}
                    error={errors.matchPref || values.matchPref === '' ? true : false}
                    helperText={errors.matchPref}
                  >
                    <MenuItem value={''} disabled>Select...</MenuItem>
                    <MenuItem value={'GI'}>GI</MenuItem>
                    <MenuItem value={'No-GI'}>No-GI</MenuItem>
                    <MenuItem value={'any'}>Any</MenuItem>
                  </TextField>
                  : <span></span>}
                  {fighterStatus === true ?
                  <TextField
                    required
                    placeholder="Select..."
                    margin="normal"
                    size="normal"
                    id="style"
                    select
                    fullWidth
                    label="Jiu Jitsu Style"
                    name="style"
                    value={values.style ?? ''}
                    onChange={handleChange}
                    error={errors.style || values.style === '' ? true : false}
                    helperText={errors.style}
                  >
                    <MenuItem value={''} disabled>Select...</MenuItem>
                    <MenuItem value={'balanced'}>Balanced</MenuItem>
                    <MenuItem value={'guard player'}>Guard Player</MenuItem>
                    <MenuItem value={'top player'}>Top Player</MenuItem>
                    <MenuItem value={'wrestler'}>Wrestler</MenuItem>
                    <MenuItem value={'high paced'}>High Paced</MenuItem>
                  </TextField>
                  : <span></span>}
                </DialogContent>
                <DialogActions
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    m: 'auto',
                    width: 'fit-content',
                  }}
                >
                  <button onClick={handleSubmit} type="submit" className="cta" value="Submit" autoFocus>
                    Save
                  </button>
                </DialogActions>
              </Dialog>
            </Box>
          </div>
        )
      );
    }
    else {
//        window.location.assign("/");
        return <div className="App-cover"><div className="App-container">Loading ...</div></div>;
    }


};


export default Profile;

//<InputLabel htmlFor="dob" error={errors.dob || values.dob === '' ? true : false}>Date of Birth</InputLabel>

//                    <OutlinedInput
//                      value={values.dob ?? ''}
//                      onBlur={handleChange}
//                      label="Date of Birth"
//                      name="dob"
//                      id="dob"
//                      type="text"
//                      inputComponent={DateMask}
//                      placeholder="YYYY-MM-DD"
//                      error={errors.dob || values.dob === '' ? true : false}
//                      helpertext={errors.dob}
//                    />

//<InputLabel htmlFor="dob" error={errors.training}>Trianing Since</InputLabel>
//                    <OutlinedInput
//                      value={values.training ?? ''}
//                      onBlur={handleChange}
//                      label="Training Since"
//                      name="training"
//                      id="training"
//                      type="text"
//                      inputComponent={DateMask}
//                      placeholder="YYYY-MM-DD"
//                      error={errors.training || values.training === '' ? true : false}
//                      helpertext={errors.training}
//                    />

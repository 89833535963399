import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const MainNav = () => {
  const { isAuthenticated } = useAuth0()

//  return (
//    isAuthenticated && (
//      <div className="navbar-links">
//        <NavLink to="/">Events</NavLink>
//        <NavLink to="library">Library</NavLink>
//      </div>
//    )
//  );
}

export default MainNav;


// <NavLink to="live?stream=99Ea9fUQ">Live</NavLink>

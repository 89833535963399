import React from "react";
// import Buy from './../components/buy';
import Moment from 'moment';
import { NavLink, Link } from "react-router-dom";
import { Video, Media } from '@vidstack/player-react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const PastEvent = (props) => {
  // URL Params
  // const queryParams = new URLSearchParams(window.location.search)
  // const session_id = queryParams.get("session_id")

  const { user, loginWithRedirect, isAuthenticated, context, callback, auth0 } = useAuth0();
  // const [didBuy, access] = useState(false);

  //Base Time Variables
  var moment = require('moment-timezone');
  var today = Moment().format('ll');
  var now = Moment().format();


  // Product Variables
  const product = props.product;
  const livestream = props.livestream;
  const item_name = props.item_name;
  const item_url = props.item_url;
  const item_price = props.item_price;
  const item_recording = props.item_recording;
  const item_video = props.item_video;
  const allowsFighters = props.allowsFighters;
  const stream_provider = props.stream_provider; 
//  const clipId = props.clipId;
//  const clipId = "8053661";
    
    
  var eventStartTime = props.eventStartTime;
  var eventEndTime = props.eventEndTime;
  var eventTimezone = props.eventTimezone;

  var item_location = props.item_location;
  var item_address = props.item_address;

  var splitAddress = item_address.split(",");
  var item_city = splitAddress[1];
  var item_state = splitAddress[2].substring(0, 3);
  var streamUrl = ""
  var streamLink = ""
  var content =
      <div className="video">
        <div className="play"><PlayCircleIcon /></div>
        <img src={item_url} />
      </div>

  if (stream_provider === "Streaming Video Provider") {
      streamUrl = `https://play.webvideocore.net/popplayer.php?it=${livestream}&is_link=1&w=720&h=405&pause=1&skin=3&repeat=&brandNW=1&start_volume=34&bg_gradient1=%23000000&bg_gradient2=%23242128&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%23242128&colorIcon=%23ffffff&colorHighlight=%23BDAA7A&direct=false&no_ctrl=&auto_hide=1&viewers_limit=1&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&mainBg_Color=%23000000&aspect_ratio=16%3A9&play_button=1&play_button_style=pulsing&sleek_player=1&stretch=&auto_play=0&auto_play_type=unMute&floating_player=none`
    
//    https://play.webvideocore.net/popplayer.php?it=1x8xm172xs2s&is_link=1&w=720&h=405&pause=1&title=ASE+XXIV&skin=3&repeat=&brandNW=1&start_volume=34&bg_gradient1=%23000000&bg_gradient2=%23000000&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%23250864&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=&auto_hide=1&viewers_limit=1&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D${clipId}%26size%3Dlarge&mainBg_Color=%23000000&aspect_ratio=16%3A9&play_button=1&play_button_style=pulsing&sleek_player=1&stretch=&auto_play=0&auto_play_type=unMute&floating_player=none
      
      
//    streamUrl = `https://play.webvideocore.net/popplayer.php?it=${item_recording}&is_link=1&w=720&h=405&pause=1&title=ASE+XXIV&skin=3&repeat=&brandNW=1&start_volume=34&bg_gradient1=%23000000&bg_gradient2=%23000000&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%23250864&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=&auto_hide=1&viewers_limit=1&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D7996396%26size%3Dlarge&mainBg_Color=%23000000&aspect_ratio=16%3A9&play_button=1&play_button_style=pulsing&sleek_player=1&stretch=&auto_play=0&auto_play_type=unMute&floating_player=none`
      
    streamLink = 
        <a href={streamUrl} target="_blank">
            {content}
        </a>
     
  }else {
    streamUrl = `/view?event=${item_recording}`;
      
    streamLink = 
    <Link to={streamUrl}>
      {content}
    </Link>  
  }
  
    
  
  const [profile, setProfile] = React.useState();

  var userEmail = null;
  var userSub = null;

  if (user) {
    userEmail = user.email;
    userSub = user.sub;
  }

  //Check access
//  const purchased = localStorage.getItem(product) || false;

  // useEffect(() => {
  //   if (purchased === true) {
  //     access(true);
  //     // console.log(purchased, didBuy);
  //     return didBuy
  //   }
  //   else {
  //     if (userSub != null && userEmail != null) {
  //       checkAccess();
  //     }
  //   }
  // });

  // async function checkAccess() {
  //   const accessData = await fetch ('/api/access', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       user: userSub,
  //       email: userEmail,
  //     }),
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     // var allPurchases = null
  //     // if (data && data[0].app_metadata.some(e => e.purchases === product)) {
  //     //   allPurchases =
  //     // }
  //     var purchaseStatus = "unpaid";
  //     // if (data && data[0].app_metadata && data[0].app_metadata.some(e => e.purchases === product)) {
  //     //   purchaseStatus = data[0].app_metadata.purchases.find(t=>t.price === product && t.status === "paid").status;
  //     // }
  //     if (data[0].app_metadata.purchases.find(t=>t.price === product && t.status === "paid").status) {
  //       purchaseStatus = "paid";
  //     }
  //
  //     if ( purchaseStatus === "paid") {
  //       localStorage.setItem(product, true);
  //       access(true)
  //       return didBuy
  //     }
  //   })
  //   .catch((error) => {
  //     console. error ('Error:', error);
  //   });
  // }

  // async function getUser() {
  //   const fetchingUser = await fetch ('/api/users', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       email: userEmail,
  //     }),
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //
  //     if (data && data.email != userEmail) {
  //       console.error("Empty array")
  //     }
  //     else {
  //       setProfile(data);
  //     }
  //   })
  //   .catch((error) => {
  //     console. error ('Error:', error);
  //   });
  // }
  var event_title = item_name;
  var splitEventName = '';
  var eventNameBase = '';
  var eventNameExtra = '';

  if (item_name.includes('(')) {
    splitEventName = item_name.split("(");
    eventNameBase = splitEventName[0];
    eventNameExtra = splitEventName[1];

    event_title = <div className="split-name"><div className="base">{eventNameBase}</div><div className="extra">({eventNameExtra}</div></div>;
  }
  

  return (
    isAuthenticated && (
      <div key={product} className="video-item">
        {streamLink}
        <h2 className="item-details">
          <div className="event-title"><div class="event_name">{event_title}</div></div>
          <div className="date thin">{Moment(eventStartTime).format('ddd, MMM Do YYYY')}</div>
        </h2>
      </div>
    )
  );


};

export default PastEvent;

// <div className="content-container" dangerouslySetInnerHTML={{ __html: content }} />

// return (
//   <div className="buy-card">
//     <img src={item_url}/>
//     <div class="content-container" dangerouslySetInnerHTML={{ __html: content }} />
//     </div>
// );

// Windows IconButton
// <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M12 12V23.408H23.408V12H12ZM24.594 12V23.408H36V12H24.594ZM12 24.594V36H23.408V24.594H12ZM24.594 24.594V36H36V24.594H24.594Z" fill="black"/>
// </svg>

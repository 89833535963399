import React from "react";
import { Video, Hls, Media } from '@vidstack/player-react';
import BuyCard from './buy-card';
import Moment from 'moment';
import { Link } from "react-router-dom";

import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Hlsjs from 'hls.js';
// hlsLibrary={Hlsjs}

import { google, outlook, office365, yahoo, ics } from "calendar-link";

//  var server = '/api';    
var server = 'https://ase-server.vercel.app/api/';


const Viewer = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const video = queryParams.get("event")

  const { user, isAuthenticated, context, callback, auth0, loginWithRedirect } = useAuth0();

  const [didBuy, access] = useState(0);

  var videoUrl = null;

  const [libraryEvent, setLibraryEvent] = React.useState({
    key: null,
    product: null,
    streamProvider: null,
    livestream: null,
    item_name: null,
    item_url: null,
    item_price: null,
    item_video: null,
    item_recording: null,
    eventStartTime: null,
    eventEndTime: null,
    eventTimezone: null,
    delay: false,
    delayDate: null,
    item_location: null,
    item_address: null,
  });

  var userEmail = '';

  if (isAuthenticated) {
    userEmail = user.email;
  }

  async function getLibraryEvent() {
    const fetchingLiveEvent = await fetch (`${server}library-event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userEmail,
        video: video,
      }),
    })
    .then((response) => response.json())
    .then((data) => {

      // setLiveEvent(data);

      setLibraryEvent({
        key: data.id,
        product: data.id,
        streamProvider: data.event.streamProvider,
        livestream: data.event.stream,
        item_name: data.event.name,
        item_url: data.event.image,
        item_price: data.event.price,
        item_video: data.event.video,
        item_recording: data.event.recording,
        eventStartTime: data.event.start,
        eventEndTime: data.event.end,
        eventTimezone: data.event.timezone,
        delay: data.event.isDelayed,
        delayDate: data.event.delayedDate,
        item_location: data.event.venueName,
        item_address: data.event.venueAddress,
      });
    })
    .catch((error) => {
      console. error ('Error:', error);
    });
  }

  // async function checkAccess() {
  //   const accessData = await fetch ('/api/access', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       user: user.sub,
  //       email: user.email,
  //     }),
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     var allPurchases = data[0].app_metadata.purchases
  //     var purchaseStatus = allPurchases.find(t=>t.price === liveEvent.product && t.status === "paid").status;
  //     if ( purchaseStatus === "paid") {
  //       localStorage.setItem(liveEvent.product, true);
  //       access(true)
  //       return didBuy
  //     }
  //   })
  //   .catch((error) => {
  //     console. error ('Error:', error);
  //   });
  // }

  useEffect(() => {
    // if (purchased === true) {
    //   access(true);
    //   return didBuy
    // }
    // else {
    //   checkAccess();
    // }

    if (libraryEvent.key === null) {
      getLibraryEvent();
//      console.log("Requested Library Event");
    }
  });

  document.title = `ASEN - Past Event: ${libraryEvent.item_name}`;

  var event_title = libraryEvent.item_name;
  var splitEventName = '';
  var eventNameBase = '';
  var eventNameExtra = '';

  if (libraryEvent.item_name && libraryEvent.item_name.includes('(')) {
    splitEventName = libraryEvent.item_name.split("(");
    eventNameBase = splitEventName[0];
    eventNameExtra = splitEventName[1];

    event_title = <div className="split-name"><div className="base">{eventNameBase}</div><div className="extra">({eventNameExtra}</div></div>;
  }

  let event_video = <Video controls loading="idle"><video controls src={libraryEvent.item_video} /></Video>

  if (libraryEvent.streamProvider === "youtube") {
    let streamUrl = `https://www.youtube.com/embed/${libraryEvent.livestream}?autoplay=1&modestbranding=1&rel=0&showinfo=0`
    event_video = <iframe width="560" height="315" src={streamUrl} rel="0" modestbranding="1" showinfo="0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  }

  if (isAuthenticated === false) {
    loginWithRedirect()

  }
  else if (isAuthenticated && libraryEvent.item_recording != null) {
    return (
      isAuthenticated && (
        <div className="App-cover">
          <div className="App-container viewer">
            <h2 className="item-details">
              <div className="event-title"><div class="event_name">{event_title}</div></div>
            </h2>
            {event_video}
          </div>
        </div>
      )
    );
  }
  else {
    return (
      <div className="App-cover">
        <div className="App-container">
          Loading ...
        </div>
      </div>
    );
  }
};

export default Viewer;

// <div className="date thin">{Moment(libraryEvent.eventStartTime).format('ddd, MMM Do')}</div>

// <iframe width="560" height="315" src="https://www.youtube.com/embed/EZaUmN7wi6E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

// <Video controls loading="idle">
    // <Media controls>
    //     <Hls controls poster="/media/images/asexvi-placeholder.png">
    //       <video controls src="/hls/99Ea9fUQ.m3u8" />
    //     </Hls>
    // </Media>
      // <video controls src="https://studio.youtube.com/video/jR87u9GqCJY/livestreaming" />
  // </Video>

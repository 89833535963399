import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import MainNav from './main-nav';
import AuthNav from './auth-nav';

const NavBar = () => {
  return (
    <nav className="nav-wrapper">
      <div className="nav-buttons">
        <Link to="/">
          <div className="brand-logo">
            <img src="./media/images/logo.png" />
          </div>
        </Link>
      </div>
      <MainNav />
      <AuthNav />
    </nav>
  );
};

export default NavBar;


// <div className="lockup">
//   <span>ASE Network</span>
//   <span className="tagline">Grapple for a cause</span>
// </div>
